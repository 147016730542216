<template>
  <div class="content">
    <div class="page-content container-fluid settings">
      <div class="row">
        <div class="col-12 col-sm">
          <!-- <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Omni Business" :subtitle="$t('widget.my-widgets')"/> -->
          <!-- <PageHeader :breadcrumbLinks="breadcrumbLinks" :subtitle="$t('widget.my-widgets')" title="Omni Business" /> -->
          <PageHeader :breadcrumbLinks="breadcrumbLinks" :subtitle="$t('widget.my-widgets')" title="Chat" />
          <!-- Search column -->
          <div class="col-8 mx-auto mb-5">
            <div class="form-wrapper">
              <form @submit.prevent="search" class="form-group col px-0 mb-0">
                <div class="input-group">
                  <input
                    placeholder=""
                    type="text"
                    class="form-control"
                    :v-model="searchQuery"
                    ref="searchInput"
                  />
                  <div class="input-group-prepend">
                    <button @click="search" type="button" class="btn icon_btn m-0">
                      <span class="material-symbols-outlined"> search </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!--/Search column -->
          <!-- Add Widget -->
          <div class="container container-add">
            <div class="row">
              <div class="col-12">
                <div class="card card-add-list">
                  <div id="close-dialog" class="btn-close" @click="closeDialog"></div>
                  <div class="card-body">
                    <div class="col-8 d-flex justify-content-center flex-column">
                      <p class="card-text" v-html="$t('widget.add-widget-desc')"></p>
                      <div class="w-100"></div>
                      <button role="button" class="btn btn-yup btn-add">{{$t('catalog.list.btn-add')}}</button>
                    </div>
                    <div class="col d-flex justify-content-center align-items-center">
                      <div class="img-wrapper">
                        <img role="button" src="@/assets/widget/add-widget.gif" alt="Add" width="367">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/Add Widget -->
          <!-- Widget list -->
          <div class="row mb-4 widget-row">
            <div class="col-12 d-flex content-title">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"><path id="widgets_FILL0_wght400_GRAD0_opsz48" d="M19.758,13.884,14.516,8.642,19.758,3.4,25,8.642ZM6,12.112V4.7h7.408v7.408ZM16.288,22.4V14.992H23.7V22.4ZM6,22.4V14.992h7.408V22.4ZM7.477,10.636h4.455V6.181H7.477Zm12.355,1.255,3.175-3.175L19.832,5.541,16.657,8.716Zm-2.067,9.032h4.455V16.469H17.764Zm-10.288,0h4.455V16.469H7.477ZM11.931,10.636ZM16.657,8.716ZM11.931,16.469ZM17.764,16.469Z" transform="translate(-6 -3.4)"></path></svg>
              <span class="h5 ml-1">{{$t('widget.my-widgets')}}</span>
            </div>
            <!-- <div class="col-12 col-sm-4 px-0 col-widget">
              <div class="card card-widget">
                <div class="card-body d-flex">
                  <img class="img-add" src="@/assets/add-dashed-circle.gif" alt="Add" width="167">
                </div>
              </div>
            </div> -->
            <template v-for="index in 1">
              <div class="col-12 col-sm-4 px-0 col-widget" :key="index">
                <router-link to="widget/config" class="card card-widget">
                  <div class="card-header">
                    <!-- <template>
                      {{ramdonState()}}
                    </template> -->
                    <!-- <span :id="`item-${index}`" class="badge" :class="[ramdonState(index) === 'filed' ? 'badge-readed' : 'badge-optional']"></span> -->
                    <span class="badge badge-optional">{{$t('generic-str.status.lbl-active')}}</span>
                    <b-dropdown right variant="link p-0" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <span class="material-symbols-outlined config_dot"> more_horiz </span><span class="sr-only">Search</span>
                      </template>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> edit </span>Renomear</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> content_copy </span>Duplicar</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined"> archive </span>Arquivar</b-dd-item>
                      <b-dd-item href="#"><span class="material-symbols-outlined text-danger"> delete_forever </span>Excluir</b-dd-item>
                    </b-dropdown>
                  </div>
                  <div class="card-body">
                    <p class="card-text h3">Título widget</p>
                    <small class="description">{mensagem de introdução} Lorem ipsum dolor sit amet consectetur. Pharetra at pretium elit aliquet vulputate vitae. Id morbi.</small>
                    <a
                      :href="`/live/simulator#${$live.accountId}`"
                      class="text-yup live-simulate-client"
                      target="_yup-chat-simulator"
                      :disabled="!$live.ready || !$live.accountId"
                    >
                      <span class="hide-mobile">{{$t('widget.sim')}}</span>
                      <span class="material-symbols-outlined ml-2">arrow_outward</span>
                    </a>
                  </div>
                  <div class="card-footer">
                    <svg role="button" width="42" height="42" viewBox="0 0 92 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.5387 4.44538L79.8487 22.6554C85.7087 24.4054 89.0487 30.6254 87.2687 36.4754L82.3487 52.6754C80.5787 58.5254 74.1487 62.8154 68.0687 62.2054L65.6387 61.9654L56.3987 77.6254L53.2287 60.7354L21.4487 57.5654C15.3687 56.9554 9.32867 51.5754 8.02867 45.6054L3.25867 12.1254C1.95867 6.15539 5.68867 2.70539 11.5387 4.45539L11.5387 4.44538Z" :stroke="'#697077'" stroke-width="4" stroke-linejoin="round"/>
                      <circle cx="23.5" cy="39.5" r="4.5" :fill="'#697077'"/>
                      <circle cx="46.5" cy="39.5" r="4.5" :fill="'#697077'"/>
                      <circle cx="69.5" cy="39.5" r="4.5" :fill="'#697077'"/>
                    </svg>
                    <div class="selected-color"></div>
                    <span class="use-widget">{{$t('widget.use-widget')}} <div class="material-symbols-outlined ml-2">keyboard_double_arrow_right</div></span>
                    <!-- <img src="@/assets/w-1.png" alt="Woman 1" srcset="">
                    <img src="@/assets/w-2.png" alt="Woman 1" srcset=""> -->
                  </div>
                </router-link>
              </div>
            </template>
          </div>
          <!--/Widget list -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
// import Pagination from '@/components/rebranding/Pagination.vue';
// import HiddenInput from '@/components/HiddenInput.vue';
// import WhatsService from '@/services/whatsapp.service';
// import Darkmode from '@/components/rebranding/SetTheme.vue';

export default {
  name: 'WidgetList',
  components: {
    PageHeader,
    // Pagination
    // HiddenInput,
    // Darkmode,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/live/reports/stat',
        2: '/live/reports/stat',
      },
      fetched: false,
      searchQuery: null,
      url: null,
      // pages: 1,
      // Test purpose
      // randomNumber: Number,
      dummyText: '',
      statusType: [
        {
          text: 'Ativo', value: 'active',
        },
        {
          text: 'Arquivado', value: 'filed',
        },
      ],
      // statusSort: [],
    };
  },
  methods: {
    search() {
      this.fetched = false;
      console.info('Search function');
      /* const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      SmsService.getBlacklists(this.form).then(
        (response) => {
          this.fetched = true;
          this.blacklists = response.data;
          // console.log('Templates: ', this.blacklists);
          result = this.blacklists.filter((item) => word.toLowerCase().split(' ').every((v) => item.mobile_number.toLowerCase().includes(v)));
          // console.log('Return result: ', result);
          this.blacklists = result;
        },
        (error) => {
          this.content = error;
        },
      ); */
    },
    /* fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      MailService.getBlacklists(this.form).then(
        (response) => {
          this.fetched = true;
          this.blacklists = response.data;
          console.log('MailService.getBlacklists', this.blacklists, response.last_page);
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    }, */
    closeDialog() {
      // document.getElementById('close-dialog').addEventListener('click', closeBubble);
      document.querySelectorAll('.container-add').forEach((el) => el.remove());
    },
    // For testing only
    ramdonState(i) {
      // this.statusSort = this.statusType.sort((a, b) => 0.5 - Math.random());
      // statusType[Math.round(Math.random() * 1)].value === 'filed' ? dummyText = 'filed' : dummyText = 'active';
      let state;
      if (this.statusType[Math.round(Math.random() * 1)].value === 'filed') {
        state = 'filed';
      } else {
        state = 'active';
      }
      setTimeout(() => {
        document.getElementById(`item-${i}`).innerHTML = `● ${this.$t(`generic-str.status.lbl-${state}`)}`;
      }, 100);
      return state;
    },
  },
};
</script>
<style scoped lang="scss">
.page-header {
  padding-bottom: 2rem;
}

.container-add{
  max-width: 1740px;
}

.card-add-list {
  background-color: #5BAF6D;
  overflow: hidden;
  position: relative;
  height: 264px;
  border-radius: 8px;
  margin-bottom: 4rem;

  >.card-body {
    display: flex;
  }

  .card-text {
    color: #fff;
    font-size: 24px;
    position: relative;
    z-index: 1;
  }

  .img-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 437px;
    height: 437px;
    background-color: #a941fa;
    border-radius: 50%; */
    z-index: 0;
  }

  .btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    background-color: transparent;
    color: #FFF;
    font-weight: 400;
    font-size: 1rem;
    border: 1px solid;
  }
}

/* Search form style */
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-group .form-control,
.rebranding .form-group .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
@media (min-width: 1660px){
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}
/*/Search Form style */
.container-add, .container-catalogs {
  max-width: 1740px;
}

/* List */
.title-list {
  color: var(--gray-font-color-5);
  font-weight: 400;
  display: block;
  padding: 8px 20px;
}
.options-cat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 10px;
  li {
    color: var(--gray-font-color-5);
  }
}
.btn.btn-add-list {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: unset !important;
  font-size: 1rem;

  &:hover, &:focus {
    color: var(--clr-yup-purple-hover) !important;
    span {
      border-color: var(--clr-yup-purple-hover);
    }
  }

  span {
    margin-left: 5px;
    border: 1px solid var(--clr-yup-purple);
    border-radius: 50%;
    font-size: 18px;
    font-weight: 400;
  }
}
hr.purple {
  width: 98%;
  border-top: 5px solid #6B23DC3D;
  border-radius: 75px;
}
.content-row {
  display: flex;
  background-color: var(--background-2);
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
  >div {
    display: flex;
    align-items: center;
    min-height: 72px;
  }
  @media (min-width: 768px) {
    div:last-child {
      justify-content: center;
    }
  }
}
.options-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.75rem;
  margin-bottom: unset;
  li, .dropdown-toggle span {
    color: var(--gray-font-color-5);
  }
  .dropdown-toggle:hover, .dropdown-toggle:focus {
    color: var(--gray-font-color);
  }
}
#close-dialog {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  transform: scale(1.25);
  padding: 10px;
  background: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
#close-dialog::after {
  content: '\e5cd';
  font-family: 'Material Symbols Outlined';
  color: #FFF;
  transform: scale(1.6);
}
.tag-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: unset;
  li {
    color: var(--gray-font-color-5);
  }
}
::v-deep {
  .dropdown .dropdown-menu.dropdown-menu-right {
    right: auto !important;
    left: 0 !important;
  }
  .dropdown-item {
    display: flex !important;
    align-items: center !important;
    gap: 10px;
    color: var(--gray-font-color-5);
  }
  .pagination {
    justify-content: end;
  }
}
.prod-img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-position: center;
}
.info-wrapper {
  display: flex;
  flex-direction: column;

  a:not(.material-symbols-outlined), span:not(.material-symbols-outlined) {
    font-weight: 400;
    padding: 8px 20px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: unset !important;
  }
  span {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: unset !important;
    color: var(--gray-font-color-5);
  }
}
.value-col {
  color: var(--gray-font-color-5);
}
.var-col {
  .var {
    padding: 4px 8px;
    background-color: #807C8D14;
    color: var(--gray-font-color-5);
    border-radius: 8px;
    margin-right: 10px;
  }
}

/* Widget */
.sort-descending {
  width: auto;
  height: 29px;
}

.filter-opt {
  display: flex;
}

/* Card widget */
.card-widget {
  /* width: 40%; */
  margin-bottom: 0;
  /* margin-right: 50px; */
  /* flex: 0 1 45%; */
  height: 100%;

  .card-header {
    display: flex;

    .dropdown {
      margin-left: auto;
    }

    .dropdown-toggle span {
      color: var(--gray-font-color-5);
    }

    .dropdown-toggle:hover, .dropdown-toggle:focus {
      color: var(--gray-font-color);
    }

    span {
      height: fit-content;
    }
  }
  .card-body {
    display: flex;
    flex-direction: column;

    p {
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    .progress-yup {
      margin-top: 1.5rem;
    }
  }
  .card-footer {
    display: flex;
    gap: 5px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  small {
    color: var(--gray-font-color);
    margin-bottom: 1.5rem;
  }
}
.widget-row {
  .content-title {
    margin-bottom: 3rem;
  }
  .col-widget {
    margin-bottom: 50px;
  }
  .col-widget:nth-of-type(2) .card.card-widget {
    box-shadow: 0px 3px 26px 5px rgba(107, 35, 220, 0.16) !important;
  }
  @media (min-width: 768px) {
    // padding: 0 8rem 0 1rem;
    padding: 0 2.5rem 0 2.5rem;
    // .col-widget:nth-of-type(2n) {
    .col-widget:nth-of-type(1n) {
      // padding-left: 5rem !important;
      .card-widget {
        margin-right: 1.5rem;
      }
    }
    // .col-widget:nth-of-type(2n+1) {
    .col-widget:nth-of-type(1n+1) {
      // padding-right: 5rem !important;
      .card-widget {
        margin-left: 1.5rem;
      }
    }
  }
  @media screen and (min-width: 1360px) and (max-width: 1660px) {
    .col-widget:nth-of-type(2n) {
      .card-widget {
        margin-right: 1rem;
      }
    }
    .col-widget:nth-of-type(2n+1) {
      .card-widget {
        margin-left: 1rem;
      }
    }
    padding: 0 2rem 0 1rem;
  }
}
.img-add {
  width: 100%;
  margin: auto;
  max-width: 250px;
  max-height: 250px;
}
/* @media (min-width: 768px) {
  .card-widget {
    min-width: 480px;
  }
} */

.progress-yup {
  width: 100%;
  /* &:before {
    content: '';
    background-image: url(/assets/import_csv.svg);
    width: 31px;
    height: 36px;
  } */
}
.live-simulate-client {
  display: flex;
  align-items: center;
  font-weight: 400;
}
.selected-color {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: var(--clr-yup-purple);
}
.use-widget {
  font-weight: bold;
  margin-left: auto;
  display: flex;
  align-items: center;
  color: var(--clr-yup-purple);
}
/*/Card workspace */
</style>
